<template>
  <v-alert
    dense
    :icon="false"
    v-if="message"
    :class="classes"
    class="break-word"
    :color="config.color"
  >
    <v-row no-gutters>
      <v-col class="d-flex align-center">
        <v-icon :color="config.iconColor"> {{ config.icon }} </v-icon>
        <span class="text--primary body-2 d-flex text-left pl-2">
          {{ message }}
        </span>
      </v-col>
    </v-row>
  </v-alert>
</template>
<script>
/**
 *  Alert Message Component
 */
export default {
  name: "AlertMessage",
  /**
   * ------- Props attributes -------
   */
  props: {
    alertType: {
      type: String,
      default: "error",
    },
    message: {
      type: String,
      default: null,
    },
    classes: {
      type: String,
      default: "py-3",
    },
  },
  /**
  |--------------------------------------------------
  | Computed properties
  |--------------------------------------------------
  */
  computed: {
    config() {
      return this.$appConfig.alert[this.alertType];
    },
  },
};
</script>
